import cn from 'classnames';
import { GetStaticProps } from 'next';
import { composeProps } from 'next-compose-props';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useSearchParam } from 'react-use';

import { ToasterNotification } from '@monorepo/components/DataDisplay';
import type { NextPageWithLayout } from '@monorepo/types';

import { ErrorPage } from '@common/components';
import { ErrorLayout } from '@common/layouts';
import { selectTheme, wrapper } from '@common/store';
import { getTranslations } from '@common/utils';
import { WebLayout } from '@marketing/layouts';

const Custom404: NextPageWithLayout = () => {
	const theme = useSelector(selectTheme);
	const Layout = theme === 'maia' ? WebLayout : ErrorLayout;
	const isExpiredCart = useSearchParam('expiredCart');
	const { t } = useTranslation(['common']);

	useEffect(() => {
		if (isExpiredCart === 'true') {
			toast.custom(
				({ visible }) => (
					<ToasterNotification
						title={t('page.funnel.splash.toast.expiredCart.title')}
						visible={visible}
						message={t('page.funnel.splash.toast.expiredCart.message')}
						status='error'
					/>
				),
				{
					ariaProps: {
						role: 'alert',
						'aria-live': 'assertive',
					},
					id: 'expired-cart-toast',
				}
			);
		}
	}, [isExpiredCart, t]);

	return (
		<Layout>
			<ErrorPage error={404} className={cn(theme === 'maia' && 'bg-primary50')} />
		</Layout>
	);
};

export const getStaticProps = wrapper.getStaticProps(() => {
	return composeProps(getTranslations) as GetStaticProps;
});

export default Custom404;
