import cn from 'classnames';
import { useRouter } from 'next/router';
import type { FC, PropsWithChildren } from 'react';

import { NavBar } from '@monorepo/components/BaseComponents';
import { WebLayoutVariants } from '@monorepo/types';

import { useLogin } from '@auth/hooks';
import { useGetGlobalsQuery } from '@cms/store';
import { Transition } from '@common/layouts';
import { MarketingFooter } from '@marketing/components';

type Props = PropsWithChildren<{ variant?: WebLayoutVariants }>;

export const WebLayout: FC<Props> = ({ variant = 'default', children }) => {
	const { userLoggedIn } = useLogin();
	const { data } = useGetGlobalsQuery({});
	const { pathname } = useRouter();
	const isB2C = pathname.includes('particulier/motorverzekering');

	return (
		<div
			className={cn(
				variant === 'simple'
					? 'grid-rows-[60px_1fr] md:grid-rows-[88px_1fr]'
					: 'grid-rows-[60px_1fr] md:grid-rows-[124px_1fr]',
				'min-h-screen-ios p-0-safe grid min-h-screen'
			)}>
			<NavBar variant={variant} isLoggedIn={userLoggedIn} items={data?.response.navItems ?? []} isB2C={isB2C} />

			<Transition>{children}</Transition>

			{data &&
				data.response?.footerColumns &&
				data.response?.footerLinks &&
				data.response?.footerSocials &&
				data.response?.footerProofs && (
					<MarketingFooter
						variant={variant}
						columns={data.response.footerColumns}
						links={data.response.footerLinks}
						socials={data.response.footerSocials}
						proofs={data.response.footerProofs}
					/>
				)}
		</div>
	);
};
